<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#68D389"
      spinner="bar-fade-scale"
    />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showSnackBar" color="#68D389" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: #ffffff">
            {{ msg }}
          </span>
        </v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: #ffffff">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap justify-end>
      <v-flex xs12 sm6 md3 pa-5>
        <v-menu
          ref="menu1"
          v-model="menu1"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="fromDate"
              label="From Date"
              outlined
              dense
              v-bind="attrs"
              v-on="on"
              clearable
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="fromDate"
            no-title
            @input="menu1 = false"
          ></v-date-picker>
        </v-menu>
      </v-flex>
      <v-flex xs12 sm6 md3 pa-5>
        <v-menu
          ref="menu2"
          v-model="menu2"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="toDate"
              label="To Date"
              outlined
              dense
              v-bind="attrs"
              v-on="on"
              clearable
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="toDate"
            no-title
            @input="menu2 = false"
          ></v-date-picker>
        </v-menu>
      </v-flex>

      <v-flex xs12 sm6 md3 lg3 xl4 pa-5>
        <v-select
          v-model="selectedFilter"
          :items="typeFilter"
          item-text="title"
          item-value="title"
          outlined
          dense
          clearable
          label="Type"
        ></v-select>
      </v-flex>
      <v-flex xs3 align-self-center>
        <v-spacer></v-spacer>
      </v-flex>
      <!-- <v-flex
        align-self-start
        xs12
        sm6
        md3
        lg2
        xl4
        v-if="entryData.length > 0"
      >
        <a
          target="_blank"
          :href="
            'https://api.wayanadwildlifesanctuary.com/purchase/getDetailedReportPdf?from=' +
            fromDate +
            '&to=' +
            toDate +
            '&filter=' +
            selectedFilter
          "
          download
          class="downlink"
        >
          <span
            style="
              font-size: 16px;
              font-family: poppinssemibold;
              color: #68d389;
            "
          >
            Download PDF</span
          >
        </a>
      </v-flex>
      <v-flex
        align-self-start
        xs12
        sm6
        md3
        lg2
        xl4
        v-if="entryData.length > 0"
      >
        <download-excel :data="json_data" :fields="json_fields">
          <span
            style="
              font-size: 16px;
              font-family: poppinssemibold;
              color: #68d389;
              cursor: pointer;
            "
          >
            Download Excel</span
          >
        </download-excel>
      </v-flex> -->
    </v-layout>
    <v-layout wrap v-if="entryData.length > 0">
      <v-flex xs12 pt-4>
        <v-layout wrap>
          <v-flex xs12 pa-2 v-for="(item, i) in entryData" :key="i">
            <v-card tile>
              <v-layout wrap pa-2>
                <v-flex xs12 sm6 pa-2 align-self-center text-left>
                  <span class="poppinssemibold">Ticket Details</span>
                </v-flex>
                <v-flex xs12 sm6 pa-2 align-self-center text-right>
                  <span class="poppinssemibold"
                    >Booking Date :
                    {{ formatDate(item.ticketDetails[0].bookingDate) }}</span
                  >
                </v-flex>
                <v-flex xs12 pa-2>
                  <v-simple-table>
                    <template v-slot:default>
                      <thead>
                        <tr style="background-color:black !important;">
                          <th style="color:white !important;" class="text-left">
                            Type
                          </th>
                          <th style="color:white !important;" class="text-left">
                            Details
                          </th>
                          <th style="color:white !important;" class="text-left">
                            Charge (INR)
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(sub, j) in item.ticketDetails" :key="j">
                          <td class="text-left">
                            <span v-if="sub.isCamera">
                              Camera
                            </span>
                            <span v-else>
                              {{ sub.type }}
                            </span>
                          </td>
                          <td class="text-left">
                            <v-layout wrap>
                              <v-flex xs12 v-if="sub.type == 'Guest'">
                                <v-layout wrap v-if="sub.guestData">
                                  <v-flex xs12>
                                    <span>
                                      {{ sub.guestData.name }}
                                    </span>
                                    <br />
                                    <span v-if="sub.guestData.phoneno">
                                      Phno: {{ sub.guestData.phoneno }}
                                    </span>
                                    <br />
                                    <span
                                      class="text-capitalize"
                                      v-if="sub.guestData.guestType"
                                    >
                                      Guest Type : {{ sub.guestData.guestType }}
                                    </span>
                                  </v-flex>
                                  <v-flex
                                    xs12
                                    pa-2
                                    align-self-center
                                    text-right
                                  >
                                    <v-dialog
                                      v-model="sub.view"
                                      max-width="600px"
                                    >
                                      <template
                                        v-slot:activator="{ on, attrs }"
                                      >
                                        <v-btn
                                          x-small
                                          style="font-family: poppinsmedium"
                                          dark
                                          class="rounded-xl"
                                          v-on="on"
                                          v-bind="attrs"
                                          color="success"
                                          @click="curItem = sub.guestData"
                                        >
                                          View more
                                        </v-btn>
                                      </template>
                                      <v-card>
                                        <v-layout
                                          wrap
                                          pa-8
                                          v-if="curItem"
                                          class="poppinsregular"
                                        >
                                          <v-flex xs12 pa-2>
                                            <span
                                              class="poppinsbold"
                                              style="border-bottom:2px solid #000;"
                                              >Guest Details</span
                                            >
                                          </v-flex>
                                          <v-flex
                                            xs12
                                            sm6
                                            text-left
                                            pa-1
                                            align-self-center
                                            v-if="curItem.name"
                                          >
                                            <span class="poppinssemibold">
                                              {{ curItem.name }}
                                            </span>
                                          </v-flex>
                                          <v-flex
                                            xs12
                                            sm6
                                            text-left
                                            pa-1
                                            align-self-center
                                            v-if="curItem.gender"
                                          >
                                            <span class="">
                                              Gender : {{ curItem.gender }}
                                            </span>
                                          </v-flex>

                                          <v-flex
                                            xs12
                                            sm6
                                            text-left
                                            pa-1
                                            align-self-center
                                            v-if="curItem.phoneno"
                                          >
                                            <span>
                                              Ph No : {{ curItem.phoneno }}
                                            </span>
                                          </v-flex>
                                          <v-flex
                                            xs12
                                            sm6
                                            text-left
                                            pa-1
                                            align-self-center
                                            v-if="curItem.guestType"
                                          >
                                            <span class="text-capitalize">
                                              Guest Type :
                                              {{ curItem.guestType }}
                                            </span>
                                          </v-flex>

                                          <v-flex
                                            xs12
                                            sm6
                                            text-left
                                            pa-1
                                            align-self-center
                                            v-if="curItem.dob"
                                          >
                                            <span>
                                              DOB :
                                              {{ formatDate(curItem.dob) }}
                                            </span>
                                          </v-flex>
                                          <v-flex
                                            xs12
                                            sm6
                                            text-left
                                            pa-1
                                            align-self-center
                                            v-if="curItem.locality"
                                          >
                                            <span class="text-capitalize">
                                              Locality : {{ curItem.locality }}
                                            </span>
                                          </v-flex>
                                          <v-flex xs12 pa-1>
                                            <v-divider></v-divider>
                                          </v-flex>
                                          <v-flex
                                            xs12
                                            v-if="
                                              curItem.guestType != 'foreigner'
                                            "
                                          >
                                            <v-layout wrap>
                                              <v-flex
                                                xs12
                                                text-center
                                                pa-1
                                                align-self-center
                                              >
                                                <span
                                                  class="text-capitalize poppinssemibold"
                                                >
                                                  ID Proof
                                                </span>
                                              </v-flex>
                                              <v-flex
                                                xs12
                                                sm6
                                                text-left
                                                pa-1
                                                align-self-center
                                                v-if="curItem.idproofs"
                                              >
                                                <span
                                                  class="text-capitalize"
                                                  v-if="curItem.idproofs.idType"
                                                >
                                                  {{ curItem.idproofs.idType }}
                                                </span>
                                              </v-flex>
                                              <v-flex
                                                xs12
                                                sm6
                                                text-left
                                                pa-1
                                                align-self-center
                                                v-if="curItem.idproofs.image"
                                              >
                                                <v-layout
                                                  wrap
                                                  v-if="
                                                    curItem.idproofs.image
                                                      .length > 0
                                                  "
                                                >
                                                  <v-flex xs12>
                                                    <a
                                                      target="_blank"
                                                      :href="
                                                        mediaURL +
                                                          curItem.idproofs
                                                            .image[0]
                                                      "
                                                    >
                                                      <v-img
                                                        :src="
                                                          mediaURL +
                                                            curItem.idproofs
                                                              .image[0]
                                                        "
                                                      ></v-img>
                                                    </a>
                                                  </v-flex>
                                                </v-layout>
                                              </v-flex>
                                            </v-layout>
                                          </v-flex>

                                          <v-flex xs12 v-else>
                                            <v-layout wrap>
                                              <v-flex
                                                xs12
                                                text-center
                                                pa-1
                                                align-self-center
                                              >
                                                <span
                                                  class="text-capitalize poppinssemibold"
                                                >
                                                  ID Proof
                                                </span>
                                              </v-flex>
                                              <v-flex
                                                xs12
                                                sm6
                                                text-left
                                                pa-1
                                                align-self-center
                                                v-if="curItem.foreignerDetails"
                                              >
                                                <span
                                                  class="text-capitalize"
                                                  v-if="
                                                    curItem.foreignerDetails
                                                      .visa
                                                  "
                                                >
                                                  Visa
                                                </span>
                                              </v-flex>
                                              <v-flex
                                                xs12
                                                sm6
                                                text-left
                                                pa-1
                                                align-self-center
                                                v-if="
                                                  curItem.foreignerDetails.visa
                                                "
                                              >
                                                <v-layout
                                                  wrap
                                                  v-if="
                                                    curItem.foreignerDetails
                                                      .visa.length > 0
                                                  "
                                                >
                                                  <v-flex xs12>
                                                    <a
                                                      target="_blank"
                                                      :href="
                                                        mediaURL +
                                                          curItem
                                                            .foreignerDetails
                                                            .visa[0]
                                                      "
                                                    >
                                                      <v-img
                                                        :src="
                                                          mediaURL +
                                                            curItem
                                                              .foreignerDetails
                                                              .visa[0]
                                                        "
                                                      ></v-img>
                                                    </a>
                                                  </v-flex>
                                                </v-layout>
                                              </v-flex>
                                              <v-flex
                                                xs12
                                                text-left
                                                pa-1
                                                align-self-center
                                                v-if="curItem.foreignerDetails"
                                              >
                                                <span
                                                  class="text-capitalize"
                                                  v-if="
                                                    curItem.foreignerDetails
                                                      .VisaValidTo
                                                  "
                                                >
                                                  Visa Validity :
                                                  {{
                                                    formatDate(
                                                      curItem.foreignerDetails
                                                        .VisaValidTo
                                                    )
                                                  }}
                                                </span>
                                              </v-flex>

                                              <v-flex
                                                xs12
                                                sm6
                                                text-left
                                                pa-1
                                                align-self-center
                                                v-if="curItem.foreignerDetails"
                                              >
                                                <span
                                                  class="text-capitalize"
                                                  v-if="
                                                    curItem.foreignerDetails
                                                      .passport
                                                  "
                                                >
                                                  Passport
                                                </span>
                                              </v-flex>
                                              <v-flex
                                                xs12
                                                sm6
                                                text-left
                                                pa-1
                                                align-self-center
                                                v-if="
                                                  curItem.foreignerDetails
                                                    .passport
                                                "
                                              >
                                                <v-layout
                                                  wrap
                                                  v-if="
                                                    curItem.foreignerDetails
                                                      .passport.length > 0
                                                  "
                                                >
                                                  <v-flex xs12>
                                                    <a
                                                      target="_blank"
                                                      :href="
                                                        mediaURL +
                                                          curItem
                                                            .foreignerDetails
                                                            .passport[0]
                                                      "
                                                    >
                                                      <v-img
                                                        :src="
                                                          mediaURL +
                                                            curItem
                                                              .foreignerDetails
                                                              .passport[0]
                                                        "
                                                      ></v-img>
                                                    </a>
                                                  </v-flex>
                                                </v-layout>
                                              </v-flex>
                                              <v-flex
                                                xs12
                                                text-left
                                                pa-1
                                                align-self-center
                                                v-if="curItem.foreignerDetails"
                                              >
                                                <span
                                                  class="text-capitalize"
                                                  v-if="
                                                    curItem.foreignerDetails
                                                      .passportValidationDate
                                                  "
                                                >
                                                  Passport Validity :
                                                  {{
                                                    formatDate(
                                                      curItem.foreignerDetails
                                                        .passportValidationDate
                                                    )
                                                  }}
                                                </span>
                                              </v-flex>
                                            </v-layout>
                                          </v-flex>
                                        </v-layout>
                                        <!-- <v-card-actions>
                                          <v-spacer></v-spacer>
                                          <v-btn
                                            color="blue darken-1"
                                            text
                                            @click="sub.view = false"
                                            >Cancel</v-btn
                                          >
                                        
                                          <v-spacer></v-spacer>
                                        </v-card-actions> -->
                                      </v-card>
                                    </v-dialog>
                                  </v-flex>
                                </v-layout>
                              </v-flex>
                              <v-flex xs12 v-else>
                                <v-layout wrap>
                                  <v-flex xs12 v-if="sub.isCamera">
                                    <span v-if="sub.camera">
                                      Camera : {{ sub.camera }}
                                    </span>
                                  </v-flex>
                                  <v-flex
                                    xs12
                                    v-if="!sub.isCamera && sub.vehicle"
                                  >
                                    <span> Vehicle : {{ sub.vehicle }} </span>
                                    <br />
                                    <span>
                                      Vehicle Number: {{ sub.vehicleNumber }}
                                    </span>
                                  </v-flex>
                                </v-layout>
                              </v-flex>
                            </v-layout>
                          </td>
                          <td class="text-left">{{ sub.charge }}</td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-flex>
              </v-layout>
            </v-card>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
    <v-layout wrap v-else>
      <v-flex xs12 py-4>
        <span style="font-family: poppinsregular">
          No entrance ticket found!!
        </span>
      </v-flex>
    </v-layout>
    <v-dialog v-model="showDesc" max-width="900px">
      <v-card>
        <v-card-title>
          <span style="font-family: poppinsbold" v-html="heads"></span>
        </v-card-title>

        <v-card-subtitle style="padding: 5%">
          <span v-html="description"></span>
        </v-card-subtitle>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" text @click="showDesc = false">Close</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-layout wrap justify-center>
      <v-flex align-self-center>
        <div class="pt-2">
          <v-pagination
            prev-icon="mdi-menu-left"
            next-icon="mdi-menu-right"
            :length="pages"
            :total-visible="7"
            v-model="currentPage"
            color="#68D389"
          ></v-pagination>
        </div>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
// import ImageLoader from "@/components/Common/imageLoader";
import axios from "axios";
export default {
  // props: ["booking", "pages", "count"],
  data() {
    return {
      currentPage: 1,
      cancelOrderDialog: false,
      cancelFullDialog: false,
      orderItems: [],
      programData: {},
      cancelAll: false,
      curItem: null,
      progName: "",
      bookingsId: "",
      ticketsId: "",
      appLoading: false,
      ServerError: false,
      showSnackBar: false,
      timeout: 5000,
      msg: null,
      isFullRefund: false,
      checkbox: false,
      allChecked: false,
      ticketIds: [],
      selectedDialog: false,
      totalSelectectedAmt: 0,
      cottageTotal: 0,
      entryData: [],
      pages: 0,
      count: 12,
      typeFilter: [{ title: "Guest" }, { title: "Other" }],

      selectedFilter: null,
      fromDate: new Date(),
      toDate: new Date(),
      menu1: false,
      menu2: false,
      showDesc: false,
      description: "",
      heads: "",

      excel: [],
      json_fields: {
        Order_No: "txnid",
        Invoice_No: "receiptno",
        "Customer Name": "payment.name",
        "Item Name": "product.name",
        "Item Type": "unitname",
        "Item Size": "sizename",
        "Quantity Purchased": "quantity",
        "Phone No": "payment.deliveryaddress.phone",
        Email: "payment.deliveryaddress.email",
        Address: "payment.deliveryaddress.streetAddress",
        "Date of placing of order": "payment.init_date",
        "Order Dispatched On": "shipped_date",
        "Courier Service": "courierservice",
        "Tracking ID": "trackingid",
        "Order Delivered on": "delivered_date",
        "Amount settled(total-PG)": "amountWithoutPG",
        "Amount Paid(Total)": "total",
      },
      json_fieldsReservation: {
        ProgrameName: "programmeName",
        BookingDate: "bookingDate",
        "Seats Reserved": "reserved",
        StartTime: "slotDetail.startTime",
        EndTime: "slotDetail.endTime",
      },
      json_data: [],
      json_dataReservation: [],
      json_meta: [
        [
          {
            key: "charset",
            value: "utf-8",
          },
        ],
      ],
    };
  },
  components: {
    // ImageLoader,
  },
  watch: {
    currentPage() {
      this.getData();
    },
    selectedFilter() {
      if (this.currentPage > 1) this.currentPage = 1;

      this.fromDate = null;
      this.toDate = null;
      this.getData();
    },
    fromDate() {
      // this.selectedFilter = null;
      this.getData();
    },
    toDate() {
      // this.selectedFilter = null;
      this.getData();
    },
  },
  mounted() {
    if (this.fromDate) {
      this.fromDate.setDate(this.fromDate.getDate() - 3 * 31);
      this.fromDate = this.fromDate.toISOString().substr(0, 10);
    }
    if (this.toDate) {
      this.toDate.setDate(this.toDate.getDate());
      this.toDate = this.toDate.toISOString().substr(0, 10);
    }
    this.getData();
  },
  methods: {
    showPopup(heads, item) {
      this.description = item;
      this.heads = heads;
      this.showDesc = true;
    },
    getData() {
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/entryticket/report",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          from: this.fromDate,
          to: this.toDate,
          count: this.count,
          page: this.currentPage,
          type: this.selectedFilter,
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.entryData = response.data.data;
          this.excel = response.data.data;
          this.json_data = this.excel;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    formatDate(item) {
      var dt = new Date(item);
      var year = dt.getFullYear();
      dt = dt.toString();
      var strTime = dt.slice(4, 10) + " " + year;
      return strTime;
    },
    formatTime(item) {
      if (!item) return;
      var hours = Number(item.split(":")[0]);
      var minutes = Number(item.split(":")[1]);
      var ampm = hours >= 12 ? "PM" : "AM";
      hours = hours % 12;
      hours = hours ? hours : 12;
      minutes = minutes < 10 ? "0" + minutes : minutes;
      var strTime = hours + ":" + minutes + " " + ampm;
      return strTime;
    },
  },
};
</script>

<style scoped>
.text {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;
}
td {
  font-family: poppinsregular;
}

th {
  font-family: poppinssemibold;
}
</style>
